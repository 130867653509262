import React from "react";

export function About() {
  return (
    <div className="main-content">
      <div className="about-blurb">
        <p>
          World War II has ended, but sinister forces threaten the fledgling peace.
        </p>

        <p>
          Intrepid researcher Mirana Orbelian will stop at nothing to uncover a mythical plant that could heal her dying father. But when her Himalayan expedition is ambushed, she’s forced to ally with Alex Pressland — a rogue ex-military pilot with a shared history that haunts them both. Together, they face ruthless Soviet commandos, deadly blizzards, and betrayal.
        </p>
        <p>
          Will they succeed, and at what cost?
        </p>
        <br />
        <br />
        <br />
      </div>
    </div>
  )
}