import React from "react";
import mixpanel from "mixpanel-browser";

export const CallToAction = ({ url, text, newTab = true }) => {

  const urlOpts = `noreferrer${newTab ? ',noopener' : ''}`

  const handleClick = () => {
    try {
      mixpanel.track(`cta-clicked`, {
        'url': url
      })
    } catch (error) {

    }

    window.open(url, '_blank', urlOpts);
  };

  return (
    <button className="cta"
      onClick={handleClick}
    >
      {text}
    </button>
  );
};
